import * as THREE from 'three';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import States from './States';

export default class Article {
  imageScale = 1;

  _element;
  _image;
  _textureLoader;
  _rotation = new THREE.Vector3(0, 0, 0);
  _geometry;
  _texture;
  _material;
  _mesh;
  _aspectRatio;
  _radius;
  _radian_interval;
  _type;
  _progress;
  _id;
  _scrollItem;
  _callback = null;

  _positionForces = [
    Maths.maxminRandom(9, 8) / 10,
    Maths.maxminRandom(9, 8) / 10,
    Maths.maxminRandom(9, 8) / 10
  ];
  _rotationForces = [
    Maths.maxminRandom(9, 8) / 10,
    Maths.maxminRandom(9, 8) / 10,
    Maths.maxminRandom(9, 8) / 10
  ];

  _sizes = {
    [States.scroll]: new THREE.Vector3(0, 0, 0),
    [States.detail]: new THREE.Vector3(0, 0, 0),
    [States.navigation]: new THREE.Vector3(0, 0, 0)
  };
  _position = {
    [States.detail]: new THREE.Vector3(0, 0, 0),
    [States.scroll]: new THREE.Vector3(0, 0, 0),
    [States.navigation]: new THREE.Vector3(0, 0, 0)
  };
  _rotation = {
    [States.detail]: new THREE.Vector3(0, 0, 0), // No se usa
    [States.scroll]: new THREE.Vector3(0, 0, 0),
    [States.navigation]: new THREE.Vector3(0, 0, 0) // No se usa
  };
  _blur = {
    [States.detail]: 0, // No se usa
    [States.scroll]: 0,
    [States.navigation]: 0
  };
  _opacity = {
    [States.detail]: 1,
    [States.scroll]: 1,
    [States.navigation]: 0
  };
  _imageScale = {
    [States.detail]: 1,
    [States.scroll]: 1,
    [States.navigation]: 1
  };
  _maskSize = {
    [States.detail]: 0.8,
    [States.scroll]: 0.8,
    [States.navigation]: 0.95
  };
  _opacityForce = {
    [States.detail]: 0.9,
    [States.scroll]: 0.9,
    [States.navigation]: 0.9
  };
  _scaleForce = {
    [States.detail]: 0.08,
    [States.scroll]: 0.08,
    [States.navigation]: 1
  };
  _maskForce = {
    [States.detail]: 0.4,
    [States.scroll]: 0.4,
    [States.navigation]: 1
  };
  _state = States.scroll;

  _yOffset = 0;

  constructor(element, opts) {
    this._element = element;
    this._radian_interval = opts.radian_interval;
    this._radius = opts.radius;
    this._id = opts.id;
    this._scrollItem = opts.scrollItem;
    this._textureLoader = new THREE.TextureLoader();
    this.imageScale = this._imageScale[this._state];

    this.setUp();
    this.createMesh();
  }

  get instance() {
    return this._mesh;
  }
  get type() {
    return this._type;
  }
  get id() {
    return this._id;
  }
  get progress() {
    return this._scrollItem.progress;
  }

  get position() {
    return this._position[States.scroll];
  }
  set position({ x, y, z }) {
    this._position[States.scroll].set(x, y, z);
  }

  set rotation({ x, y, z }) {
    this._rotation[States.scroll].set(x, y, z);
  }

  set blur(b) {
    this._blur[States.scroll] = b;
  }

  set opacity(o) {
    this._opacity[States.scroll] = o;
  }

  setUp() {
    // Parsing article content
    this._image = GetBy.selector('img', this._element)[0];
    this._texture = this._textureLoader.load(this._image.getAttribute('src'));
  }

  loop() {
    const state = this._state;

    const x =
      this._mesh.position.x +
      (this._position[state].x - this._mesh.position.x) *
        this._positionForces[0];
    const y =
      this._mesh.position.y +
      (this._position[state].y - this._mesh.position.y) *
        this._positionForces[1] +
      this._yOffset;
    const z =
      this._mesh.position.z +
      (this._position[state].z - this._mesh.position.z) *
        this._positionForces[2];

    const rotX =
      this._mesh.rotation.x +
      (this._rotation[state].x - this._mesh.rotation.x) *
        this._rotationForces[0];
    const rotY =
      this._mesh.rotation.y +
      (this._rotation[state].y - this._mesh.rotation.y) *
        this._rotationForces[1];
    const rotZ =
      this._mesh.rotation.z +
      (this._rotation[state].z - this._mesh.rotation.z) *
        this._rotationForces[2];

    const blur =
      this._material.uniforms.blur.value +
      (this._blur[state] - this._material.uniforms.blur.value) * 0.9;
    const opacity =
      this._material.uniforms.opacity.value +
      (this._opacity[state] - this._material.uniforms.opacity.value) *
        this._opacityForce[state];

    this._mesh.position.set(x, y, z);
    this._mesh.rotation.set(rotX, rotY, rotZ);
    this._material.uniforms.blur.value = blur;
    this._material.uniforms.opacity.value = opacity;

    if (this._type === 'image' && state === States.detail) {
      const scrollPos = Math.min(Scroll.y, Metrics.HEIGHT);
      this._yOffset = -scrollPos * 0.4;
    } else {
      this._yOffset = 0;
    }
  }

  getSizes() {
    const { width, height } = this._image.getBoundingClientRect();
    this._aspectRatio = width / height;

    this._sizes[States.scroll].set(width, height, 1);
    this._sizes[States.detail].set(width, height, 1);
    this._sizes[States.navigation].set(width, height, 1);
  }

  createMesh() {}

  toggleState(state) {
    this._state = state;
  }

  directToggleState(state) {
    this.toggleState(state);

    this._mesh.position.x = this._position[state].x;
    this._mesh.position.y = this._position[state].y;
    this._mesh.position.z = this._position[state].z;

    this._mesh.rotation.x = this._rotation[state].x;
    this._mesh.rotation.y = this._rotation[state].y;
    this._mesh.rotation.z = this._rotation[state].z;

    this._mesh.scale.x = this._sizes[state].x;
    this._mesh.scale.y = this._sizes[state].y;

    this._material.uniforms.blur.value = this._blur[state];
    this._material.uniforms.opacity.value = this._opacity[state];
  }

  detailResize() {}

  resize() {}
}
