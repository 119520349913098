import gsap, { Power2, Linear } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

import { GetBy } from '../_app/cuchillo/core/Element';

gsap.registerPlugin(DrawSVGPlugin);

export default class Loader {
  _loader;
  _progress;

  static show() {
    this._loader = GetBy.id('page-loader');
    this._progress = GetBy.selector('.__loader');

    this._loader.style.display = 'flex';
    gsap.set(this._progress, { drawSVG: '0%' });
  }

  static update(progress) {
    gsap.killTweensOf(this._progress);
    gsap.to(this._progress, {
      duration: 0.1,
      drawSVG: `${progress * 100}%`,
      ease: Power2.easeOut
    });
  }

  static hide() {
    gsap.killTweensOf(this._progress);
    gsap.to(this._progress, {
      duration: 0.5,
      drawSVG: '100%',
      ease: Power2.easeOut
    });
    for (let index = 0; index < this._progress.length; index++) {
      const element = this._progress[index];
      gsap.to(element.parentNode, {
        opacity: 0,
        delay: 1,
        duration: 0.8,
        ease: Power2.easeOut
      });
    }
    gsap.to(GetBy.selector('.__title', this._loader)[0], {
      opacity: 0,
      duration: 1,
      ease: Linear.easeNone,
      delay: 0.8
    });
    gsap.to(this._loader, {
      opacity: 0,
      duration: 1.5,
      ease: Linear.easeNone,
      delay: 1.5,
      onComplete: () => {
        this._loader.style.display = 'none';
      }
    });
  }
}
