import gsap, { Power2 } from 'gsap';

import _Header from '../_app/cuchillo/layout/Header';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';
import Timeline from '../3D/Timeline';

export default class Header extends _Header {
  _creditsOpen = false;

  static options = {
    show: {
      duration: 0.8,
      delay: 0,
      ease: Power2.easeOut
    },
    hide: {
      duration: 0.8,
      delay: 0,
      ease: Power2.easeIn
    }
  };

  static isShow = false;

  static init() {
    super.init();

    this.toggle = GetBy.id('menu-toggle');
    this.toggle.addEventListener(Basics.clickEvent, () => {
      Timeline.toggleNavigation();

      gsap.to(this.creditsToggle, {
        y: this._creditsOpen ? this.height : 0,
        duration: 0.8,
        delay: 0,
        ease: Power2.easeOut
      });

      this._creditsOpen = !this._creditsOpen;
    });

    this.creditsToggle = GetBy.id('credits-navbar');
  }

  static show() {
    super.show();
    Timeline.toggleScroller(true);
  }

  static hide() {
    super.hide();
    Timeline.toggleScroller(false);

    gsap.to(this.creditsToggle, {
      y: this.height,
      duration: 0.8,
      delay: 0,
      ease: Power2.easeOut
    });
    this._creditsOpen = false;
  }
}
