import lottie from 'lottie-web';
import { gsap, Power0, Power2, Power3 } from 'gsap';

// import { isTouch } from '../_app/cuchillo/core/Basics';
import Win from '../_app/cuchillo/windows/Window';
// import VScroll from '../_app/cuchillo/scroll/VScroll';
// import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';

class Credits extends Win {
  _content;
  _logo;
  _animation;
  active = false;

  constructor(__container) {
    super(__container, 'credits');

    this.directHide();

    this._content = Array.from(GetBy.selector('#credits .content'));
    this._logo = GetBy.selector('#credits .logo')[0];

    lottie.loadAnimation({
      name: 'logo',
      container: this._logo, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/assets/json/longlead-logo.json' // the path to the animation json
    });

    this._animation = lottie.getRegisteredAnimations()[0];

    // if (!isTouch) {
    //   this.engine = new VScroll(this.options);
    //   this.engine.addAll('[scroll-credits-item]');
    //   this.engine.resize();

    //   this._call = () => this.loop();
    // }
  }

  show__effect(__d = 0) {
    gsap.set(this.container, { alpha: 0 });
    gsap.set(this._content, { alpha: 1 });

    gsap.to(this.container, {
      alpha: 1,
      duration: 0.3,
      ease: Power0.easeOut
    });

    this._animation.goToAndStop(0, true);
    this._animation.play();
    this._animation.addEventListener('enterFrame', (e) => {
      if (e.currentTime >= e.totalTime / 3) {
        this._animation.pause();
        this._animation.removeEventListener('enterFrame');
      }
    });
  }

  hide__effect(__d = 0) {
    this._animation.goToAndPlay(80, true);

    gsap.to(this._content, {
      alpha: 0,
      duration: 0.3,
      delay: 2,
      ease: Power3.easeOut
    });

    gsap.to(this.container, {
      alpha: 0,
      delay: 2.1,
      duration: 0.3,
      ease: Power2.easeOut,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  actionButtonToggle(item) {
    super.actionButtonToggle(item);
  }

  show() {
    super.show();

    this.active = true;

    // if (this.engine) {
    //   this.enableScroll();
    // }
  }

  hide() {
    super.hide();

    this.active = false;

    // if (this.engine) {
    //   this.disableScroll();
    // }
  }

  directHide() {
    gsap.set(this.container, { alpha: 0 });
    super.directHide();
    // gsap.ticker.remove(this._call);
  }

  // enableScroll() {
  //   if (!this.engine.enabled) this.engine.enabled = true;
  //   gsap.ticker.add(this._call);
  // }

  // disableScroll() {
  //   if (this.engine.enabled) this.engine.enabled = false;
  //   gsap.ticker.remove(this._call);
  // }

  // loop() {
  //   this.engine.loop();
  // }

  resize() {
    super.resize();

    // if (this.engine) {
    //   this.engine.resize();
    // }
  }
}

export const WinCredits = new Credits(GetBy.id('credits'));
