import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';

export default class NotFound extends Default {
  id;
  isWrapAutoRemove = true;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor() {
    super();
  }

  beforeShow () {
    document.body.classList.add("body-not-found");

    // super.beforeShow();
  }
  afterHide () {
    document.body.classList.remove("body-not-found");

    super.afterHide();
  }
}

ControllerPage._addPage("not-found", NotFound)
