import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import Timeline from '../3D/Timeline';

class ScrollItem__Billboard extends VScroll_Item {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onShow = () => {
      Timeline.directGoToArticle(this.item.dataset.id);
    };
    this.onHide = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  dispose () {
    super.dispose();
  }

  resize (__w, __h) {
    super.resize(__w, __h)
  }
}

Scroll._registerClass('scroll-detail-article', ScrollItem__Billboard);