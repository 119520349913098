import * as THREE from 'three';

import { TEXT_FRAGMENT } from '../shaders/fragment';
import { TEXT_VERTEX } from '../shaders/vertex';

import Article from './Article';
import { Maths } from '../_app/cuchillo/utils/Maths';
import States from './States';

export default class ArticleText extends Article {
  _type = 'text';

  constructor(element, opts) {
    super(element, opts);
  }

  createMesh () {
    this.getSizes();

    const planeAspect = 1 / this._aspectRatio;
    const sizes = this._sizes[States.scroll];

    let a1;
    let a2;

    if (sizes.y / sizes.x > planeAspect) {
      const height = (sizes.x / sizes.y) * planeAspect;
      // Canvas more horizontal than image
      a1 = 1;
      a2 = height;
    } else {
      // Canvas more vertical than image
      a1 = ((sizes.y / sizes.x) / planeAspect);
      a2 = 1;
    }

    const uniforms = {
      texture1: { type: 't', value: this._texture },
      blur: { type: 'f', value: 0.0 },
      opacity: { type: 'f', value: 0.0 },
      resolution: {
        type: 'v2', value: {
          x: a1,
          y: a2
        }
      }
    };

    this._geometry = new THREE.PlaneBufferGeometry(1, 1, 16, 16);
    this._material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      fragmentShader: TEXT_FRAGMENT,
      vertexShader: TEXT_VERTEX,
      transparent: true
    });
    this._mesh = new THREE.Mesh(this._geometry, this._material);
    this._mesh.scale.set(this._sizes[States.scroll].x, this._sizes[States.scroll].y, this._sizes[States.scroll].z);
    this._mesh.element = this;
  }

  loop () {
    const state = this._state;

    super.loop();

    const scaleX = this._mesh.scale.x + (this._sizes[state].x - this._mesh.scale.x) * this._scaleForce[state] + this._yOffset / 2;
    const scaleY = this._mesh.scale.y + (this._sizes[state].y - this._mesh.scale.y) * this._scaleForce[state] + this._yOffset / 2;

    this._mesh.scale.set(scaleX, scaleY, 1);
  }
}