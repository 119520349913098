export const IMAGE_FRAGMENT = `
    #define PI 3.1415926538

    uniform float maskSize;
    uniform float imageScale;
    uniform float scrollScale;
    uniform float aspectRatio;
    uniform float blur;
    uniform float opacity;
    uniform sampler2D texture1;
    uniform vec2 resolution;
    varying vec2 vUv;
    float scaleCenter = 0.5;

    // GAUSSIAN BLUR SETTINGS
    float directions = 16.0; // BLUR DIRECTIONS (Default 16.0 - More is better but slower)
    float quality = 3.0; // BLUR QUALITY (Default 4.0 - More is better but slower)
    float size = 8.0; // BLUR SIZE (Radius)
    // GAUSSIAN BLUR SETTINGS

    float Circle(in vec2 st, in float radius) {
        // Assumes the image is horizontal.
        // Recenters the pixel to the center of the plane.
        // The plane is 1x1 so we change the x size according to the image aspect ratio.
        vec2 dist = st - vec2(scaleCenter);
        dist.x *= aspectRatio;

        // Creates a smooth step, deciding if the pixel needs to be painted or
        // not according the given radius.
        return 1. - smoothstep(radius - (radius * 0.01), radius + (radius * 0.01), dot(dist, dist) * 4.0);
    }

    vec4 Blur2D(sampler2D image, vec2 uv) {
        vec4 color = vec4(0.0);

        color += texture2D(image, vec2(uv.x - 4.9 * blur, uv.y - 4.9 * blur)) * 0.051;
        color += texture2D(image, vec2(uv.x - 3.9 * blur, uv.y - 3.9 * blur)) * 0.0918;
        color += texture2D(image, vec2(uv.x - 2.9 * blur, uv.y - 2.9 * blur)) * 0.12245;
        color += texture2D(image, vec2(uv.x - 1.9 * blur, uv.y - 1.9 * blur)) * 0.1531;
        color += texture2D(image, vec2(uv.x, uv.y)) * 0.1633;
        color += texture2D(image, vec2(uv.x + 1.9 * blur, uv.y + 1.9 * blur)) * 0.1531;
        color += texture2D(image, vec2(uv.x + 2.9 * blur, uv.y + 2.9 * blur)) * 0.12245;
        color += texture2D(image, vec2(uv.x + 3.9 * blur, uv.y + 3.9 * blur)) * 0.0918;
        color += texture2D(image, vec2(uv.x + 4.9 * blur, uv.y + 4.9 * blur)) * 0.051;

        return color;
    }

    void main() {
        // Applies aspect ratio to plane
        vec2 newUV = (vUv - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);

        // Gets new centered coords for the texture and scales the image
        // to the plane size with a ratio for scroll (when in detail page) and a ratio for animation
        vec2 newUVScale = (newUV - scaleCenter) * (imageScale + scrollScale) + scaleCenter;

        // Gets texture pixel color
        // vec4 textureColor = texture2D(texture1, newUVScale);
        vec4 textureColor = Blur2D(texture1, newUVScale);

        // Obtains the circle and multiplies by pixel colour
        vec3 color = textureColor.rgb * Circle(vUv, maskSize);

        // Calculates the the color outcome from the smoothstep
        // If the color is black, we paint alpha 0.0
        // If the color is different than black, we paint the actual color
        float newOpacity = opacity;
        // float bgOpacity = 1.;

        if (color == vec3(0.0)) {
            newOpacity = 0.0;
            // bgOpacity = 0.0;
        }

        // vec4 black = vec4(0., 0., 0., bgOpacity);
        // vec4 c = mix(vec4(color, newOpacity), black, .5);

        gl_FragColor = vec4(color, newOpacity);
    }
`;

export const TEXT_FRAGMENT = `
    uniform float blur;
    uniform float opacity;
    uniform sampler2D texture1;
    uniform vec2 resolution;
    float scaleCenter = 0.5;

    varying vec2 vUv;

    // GAUSSIAN BLUR SETTINGS
    float directions = 16.0; // BLUR DIRECTIONS (Default 16.0 - More is better but slower)
    float quality = 3.0; // BLUR QUALITY (Default 4.0 - More is better but slower)
    float size = 8.0; // BLUR SIZE (Radius)
    // GAUSSIAN BLUR SETTINGS

    vec4 Blur2D(sampler2D image, vec2 uv) {
        vec4 color = vec4(0.0);

        color += texture2D(image, vec2(uv.x - 4.0 * blur, uv.y)) * 0.051;
        color += texture2D(image, vec2(uv.x - 3.0 * blur, uv.y)) * 0.0918;
        color += texture2D(image, vec2(uv.x - 2.0 * blur, uv.y)) * 0.12245;
        color += texture2D(image, vec2(uv.x - 1.0 * blur, uv.y)) * 0.1531;
        color += texture2D(image, vec2(uv.x, uv.y)) * 0.1633;
        color += texture2D(image, vec2(uv.x + 1.0 * blur, uv.y)) * 0.1531;
        color += texture2D(image, vec2(uv.x + 2.0 * blur, uv.y)) * 0.12245;
        color += texture2D(image, vec2(uv.x + 3.0 * blur, uv.y)) * 0.0918;
        color += texture2D(image, vec2(uv.x + 4.0 * blur, uv.y)) * 0.051;

        return color;
    }

    void main() {
        vec2 newUV = (vUv - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);
        vec4 color = Blur2D(texture1, newUV);

        gl_FragColor = vec4(color.rgb, color.a * opacity);
    }
`;