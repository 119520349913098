import gsap from 'gsap';

import { Basics } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import Wrap from '../layout/Wrap';
import Timeline from '../3D/Timeline';

export default class Default extends Page {
  copyLink;

  constructor() {
    super();

    this._copy = () => {
      this.handleCopyLink();
    };

    this.copyLink = GetBy.id('copy');
  }

  //SHOW
  beforeShow() {
    super.beforeShow();

    this.copyLink.addEventListener(Basics.clickEvent, this._copy);
  }

  show__effect(__call) {
    Wrap.show(() => {
      this.afterShow();
    });
  }

  afterShow() {
    super.afterShow();
  }

  //HIDE
  beforeHide() {
    Timeline.closeNavigation();
  }

  hide__effect() {
    Wrap.hide(() => {
      this.afterHide();
    });
  }

  afterHide() {
    super.afterHide();

    this.copyLink.removeEventListener(Basics.clickEvent, this._copy);
  }

  handleCopyLink() {
    const dummy = document.createElement('input'),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    const copied = GetBy.id('copied');

    gsap.set(copied, { opacity: 1 });
    gsap.to(copied, {
      opacity: 0,
      duration: 0.2,
      delay: 1.5
    });
  }

  //RESIZE
  resize() {
    super.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);
