import * as THREE from 'three';
import { TimelineScroll } from '../scroll/TimelineScroll';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class Starfield {
  _scene = new THREE.Scene();
  _starsHolder;

  tick = 0;
  points;

  _renderer;
  orthoCamera;
  _perspectiveCamera;
  fullscreenQuadGeometry;
  fadePlane;
  resultPlane;
  uvMatrix = new THREE.Matrix3();

  options = {
    totalStars: 9000,
    fadeFactor: 0.2,
    starSize: [3, 4.5],
    size: [5, 40]
  };

  constructor(__renderer, __scene, __perspectiveCamera, __nparticles) {
    this._renderer = __renderer;
    //this._scene = __scene;
    this._perspectiveCamera = __perspectiveCamera;
    //this.options.totalStars = __nparticles? __nparticles : this.options.totalStars;
    this.initParticles();
    this.initCameraOrto();
    this.initPlanes();
    this.initBuffers();

    this._parentScene = __scene;

    //this._parentScene.add(this._scene);
  }

  initParticles() {
    const positions = new Float32Array(this.options.totalStars * 3);
    const colors = new Float32Array(this.options.totalStars * 3);
    const randomColors = [0x4b658a, 0xc55d4b];
    const sizes = new Float32Array(this.options.totalStars);
    const rotations = new Float32Array(this.options.totalStars);
    const sCoef = new Float32Array(this.options.totalStars);
    const position = new THREE.Vector3();
    let color;

    for (let i = 0; i < this.options.totalStars; i++) {
      const theta = 360 * Math.random();
      const dist = Math.sqrt(
        (Math.random() * (1000 ^ (2 - 2000) ^ 2) + 2000) ^ 2
      );
      const x = dist * Math.cos(theta);
      const y = dist * Math.sin(theta);

      position.set(x, y, 1000 + THREE.Math.randFloatSpread(2000));
      position.toArray(positions, i * 3);
      color = new THREE.Color(randomColors[Maths.maxminRandom(1, 0)]);
      color.toArray(colors, i * 3);
      sizes[i] = THREE.Math.randFloat(
        this.options.starSize[0],
        this.options.starSize[1]
      );
      sCoef[i] = THREE.Math.randFloat(0.01, 0.02);
      rotations[i] = THREE.Math.randFloat(0, Math.PI);
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
    geometry.setAttribute('rotation', new THREE.BufferAttribute(rotations, 1));
    geometry.setAttribute('speedMod', new THREE.BufferAttribute(rotations, 1));
    geometry.setAttribute('sCoef', new THREE.BufferAttribute(sCoef, 1));

    const material = new THREE.ShaderMaterial({
      uniforms: {
        uTime: { value: 0 },
        speed: { value: 1 },
        uTexture: {
          value: new THREE.TextureLoader().load('/assets/images/star.png')
        }
      },
      vertexShader: `
      uniform float uTime;
      uniform float speed;
      attribute vec3 color;
      attribute float size;
      attribute float speedMod;
      attribute float rotation;
      attribute float sCoef;
      varying vec4 vColor;
      varying float vRotation;
      void main() {
        vColor = vec4(color, 1.);
        vRotation = rotation;

        vec3 p = vec3(position);
        p.z = -500. + (mod((position.z + speed) + uTime * (sCoef*(5. * speedMod)), 2000.));       

        vec4 mvPosition = modelViewMatrix * vec4(p, 1.);
        gl_Position = projectionMatrix * mvPosition;

        float psize = size * (200. / -mvPosition.z);
        gl_PointSize = psize;
      }
    `,
      fragmentShader: `
      uniform sampler2D uTexture;
      varying vec4 vColor;
      varying float vRotation;
      void main() {
        vec2 v = gl_PointCoord - .5;
        float ca = cos(vRotation), sa = sin(vRotation);
        mat2 rmat = mat2(ca, -sa, sa, ca);
        gl_FragColor = vColor * texture2D(uTexture, v*rmat + .5);
      }
    `,
      blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true
    });

    this._starsHolder = new THREE.Points(geometry, material);
    this._scene.add(this._starsHolder);
  }

  initCameraOrto() {
    const left = -innerWidth / 2;
    const right = innerWidth / 2;
    const top = -innerHeight / 2;
    const bottom = innerHeight / 2;
    const near = -100;
    const far = 100;
    this.orthoCamera = new THREE.OrthographicCamera(
      left,
      right,
      top,
      bottom,
      near,
      far
    );
    this.orthoCamera.position.z = -10;
    this.orthoCamera.lookAt(new THREE.Vector3(0, 0, 0));
  }

  initPlanes() {
    const fullscreenQuadGeometry = new THREE.PlaneGeometry(
      innerWidth,
      innerHeight
    );
    // To achieve the fading out to black, we will use THREE.ShaderMaterial
    const fadeMaterial = new THREE.ShaderMaterial({
      transparent: true,
      // Pass the texture result of our rendering to Framebuffer 1 as uniform variable
      uniforms: {
        inputTexture: { value: null },
        fadeFactor: { value: this.options.fadeFactor },
        uvMatrix: { value: this.uvMatrix }
      },
      vertexShader: `
        uniform mat3 uvMatrix;
        varying vec2 vUv;
        void main () {
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          vUv = (uvMatrix * vec3(uv, 1.0)).xy ;
        }
      `,
      fragmentShader: `
        uniform sampler2D inputTexture;
        uniform float fadeFactor;
        varying vec2 vUv;
        void main () {
          
          vec4 texColor = texture2D(inputTexture, vUv);
          vec4 fadeColor = vec4(0.1, .1, .1, 1);
          gl_FragColor = mix(texColor, fadeColor, fadeFactor);
        }
      `
    });

    // Create our fadePlane
    this.fadePlane = new THREE.Mesh(fullscreenQuadGeometry, fadeMaterial);

    // create our resultPlane
    // Please notice we don't use fancy shader materials for resultPlane
    // We will use it simply to copy the contents of fadePlane to the device screen
    // So we can just use the .map property of THREE.MeshBasicMaterial
    const resultMaterial = new THREE.MeshBasicMaterial({
      map: null,
      transparent: true
    });
    this.resultPlane = new THREE.Mesh(fullscreenQuadGeometry, resultMaterial);
    //this.resultPlane.scale.y *= 2;
  }

  initBuffers() {
    this.framebuffer1 = new THREE.WebGLRenderTarget(innerWidth, innerHeight, {
      format: THREE.RGBAFormat
    });
    this.framebuffer2 = new THREE.WebGLRenderTarget(innerWidth, innerHeight, {
      format: THREE.RGBAFormat
    });
    // Before we start using these framebuffers by rendering to them,
    // let's explicitly clear their pixel contents to #111111
    // If we don't do this, our persistence effect will end up wrong,
    // due to how accumulation between step 1 and 3 works.
    // The first frame will never fade out when we mix Framebuffer 1 to
    // Framebuffer 2 and will be always visible.
    // This bug is better observed, rather then explained, so please
    // make sure to comment out these lines and see the change for yourself.

    this._renderer.setClearColor(0x1c1c1c);
    this._renderer.setRenderTarget(this.framebuffer1);
    this._renderer.clearColor();
    this._renderer.setRenderTarget(this.framebuffer2);
    this._renderer.clearColor();
  }

  loop() {
    const modSpeed = isMobile ? 0.008 : 0.006;
    const modRot = isMobile ? 0.0002 : 0.0001;

    const speed = TimelineScroll.speed * modSpeed;
    const rotation = TimelineScroll.speed * modRot;

    this.tick++;
    this._starsHolder.material.uniforms.uTime.value = this.tick;
    this._starsHolder.material.uniforms.speed.value -= speed;
    this._starsHolder.rotation.z -= rotation;

    this._renderer.autoClearColor = false;
    // Set Framebuffer 2 as active WebGL framebuffer to render to
    this._renderer.setRenderTarget(this.framebuffer2);
    this.fadePlane.material.uniforms.inputTexture.value =
      this.framebuffer1.texture;
    this._renderer.render(this.fadePlane, this.orthoCamera);

    this._renderer.render(this._scene, this._perspectiveCamera);

    this._renderer.setRenderTarget(null);

    this.resultPlane.material.map = this.framebuffer2.texture;
    this._renderer.render(this.resultPlane, this.orthoCamera);

    this._renderer.render(this._parentScene, this._perspectiveCamera);

    const swap = this.framebuffer1;
    this.framebuffer1 = this.framebuffer2;
    this.framebuffer2 = swap;

    /*const uvScaleX = 1;
    const uvScaleY = 1;
    const rotation = THREE.MathUtils.degToRad(1)
    this.uvMatrix.setUvTransform(0, 0, 1, 1, 0, 0.5, 0.5)*/

    return;

    /* this._renderer.autoClearColor = false

    // Set Framebuffer 2 as active WebGL framebuffer to render to
    this._renderer.setRenderTarget(this.framebuffer2)

    // <strong>Step 1</strong>
    // Render the image buffer associated with Framebuffer 1 to Framebuffer 2
    // fading it out to pure black by a factor of 0.05 in the fadeMaterial
    // fragment shader
    this.fadePlane.material.uniforms.inputTexture.value = this.framebuffer1.texture
    this._renderer.render(this.fadePlane, this.orthoCamera)

    // <strong>Step 2</strong>
    // Render our entire scene to Framebuffer 2, on top of the faded out 
    // texture of Framebuffer 1.
    this._renderer.render(this._scene, this._perspectiveCamera)

    // Set the Default Framebuffer (device screen) represented by null as active WebGL framebuffer to render to.
    this._renderer.setRenderTarget(null)
    
    // <strong>Step 3</strong>
    // Copy the pixel contents of Framebuffer 2 by passing them as a texture
    // to resultPlane and rendering it to the Default Framebuffer (device screen)
    this.resultPlane.material.map = this.framebuffer2.texture
    this._renderer.render(this.resultPlane, this.orthoCamera)

    // <strong>Step 4</strong>
    // Swap Framebuffer 1 and Framebuffer 2
    const swap = this.framebuffer1
    this.framebuffer1 = this.framebuffer2
    this.framebuffer2 = swap*/
  }

  dispose() {
    this._renderer.dispose();
    this.framebuffer1.dispose();
    this.framebuffer2.dispose();
  }
}
