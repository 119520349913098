import * as THREE from 'three';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import States from './States';
import Article from './Article';
import Timeline from './Timeline';

import { IMAGE_VERTEXT } from '../shaders/vertex';
import { IMAGE_FRAGMENT } from '../shaders/fragment';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class ArticleImage extends Article {
  _hover = false;
  _type = 'image';
  _positionForces = [0.4, 0.5, 0.1];
  _tempOpacity = 1;

  _maskSize = {
    [States.detail]: 1,
    [States.scroll]: 0.8,
    [States.navigation]: 0.95
  };
  _opacity = {
    [States.detail]: 1,
    [States.scroll]: 1,
    [States.navigation]: 0
  };
  _scaleForce = {
    [States.detail]: 0.025,
    // [States.detail]: 0.5,
    [States.scroll]: 0.05,
    [States.navigation]: 0.4
  };
  _opacityForce = {
    [States.detail]: 0.05,
    [States.scroll]: 0.9,
    [States.navigation]: 0.9
  };

  constructor(element, opts) {
    super(element, opts);
  }

  get link() {
    return this._element.dataset.link;
  }
  set position({ x, y, z }) {
    this._position[States.scroll].set(x, y, z);
  }
  set rotation({ x, y, z }) {
    this._rotation[States.scroll].set(x, y, z);
  }
  set blur(b) {
    this._blur[States.scroll] = b;
  }
  set hover(_ho) {
    this._hover = _ho;
  }

  getSizes() {
    // Scroll
    const { width, height } = this._image.getBoundingClientRect();
    this._aspectRatio = width / height;
    this._sizes[States.scroll].set(height, height, 1); // Square plane on scroll

    // Detail
    const screenAspectRatio = Metrics.WIDTH / Metrics.HEIGHT;
    this._sizes[States.detail].set(
      // Timeline.fov_y * screenAspectRatio,
      Timeline.fov_y * Timeline.scaleFactor,
      Timeline.fov_y * Timeline.scaleFactor,
      1
    );
    // this._maskSize[States.detail] = Math.max(1, screenAspectRatio * 2.5);

    // Navigation
    // Check screen ratio and select the corresponding menu
    // On resize check
    const element = GetBy.selector(
      `[data-article="${this._id}"]`,
      Timeline.navigation
    )[0];
    const {
      width: navWidth,
      height: navHeight,
      x,
      y
    } = element.getBoundingClientRect();
    const positions = this.domPositionTo3D(x, y);

    // Using the fullscreen FOV calculated for the detail to get a relative size for smaller elements
    let newHeight = (navHeight * Timeline.fov_y) / Metrics.HEIGHT;
    let newWidth =
      (navWidth * Timeline.fov_y * screenAspectRatio) / Metrics.WIDTH;
    let newX = (positions.x * Timeline.fov_y) / Metrics.HEIGHT;
    let newY =
      (positions.y * Timeline.fov_y * screenAspectRatio) / Metrics.WIDTH;

    this._sizes[States.navigation].set(newWidth, newHeight, 1);
    this._position[States.navigation].set(
      newX + newWidth / 2,
      newY - newHeight / 2,
      0
    );
  }

  calculateResolution() {
    const { width, height } = this._image.getBoundingClientRect();
    const planeAspect = this._sizes[this._state].y / this._sizes[this._state].x;
    let a1;
    let a2;

    if (height / width > planeAspect) {
      const h = (width / height) * planeAspect;
      // Canvas more horizontal than image
      a1 = 1;
      a2 = h;
    } else {
      // Canvas more vertical than image
      a1 = height / width / planeAspect;
      a2 = 1;
    }

    return {
      x: a1,
      y: a2
    };
  }

  createMesh() {
    this.getSizes();

    const { x, y } = this.calculateResolution();
    const uniforms = {
      texture1: { type: 't', value: this._texture },
      maskSize: { type: 'f', value: 0.65 },
      imageScale: { type: 'f', value: 1.0 },
      scrollScale: { type: 'f', value: 0.0 },
      aspectRatio: { type: 'f', value: this._aspectRatio },
      velocity: { type: 'f', value: 0.0 },
      blur: { type: 'f', value: 0.0 },
      opacity: { type: 'f', value: 0.0 },
      resolution: {
        type: 'v2',
        value: { x, y }
      }
    };

    this._geometry = new THREE.PlaneBufferGeometry(1, 1, 16, 16);
    this._material = new THREE.ShaderMaterial({
      uniforms,
      fragmentShader: IMAGE_FRAGMENT,
      vertexShader: IMAGE_VERTEXT,
      transparent: true
    });

    const sizes = this._sizes[States.scroll];
    this._mesh = new THREE.Mesh(this._geometry, this._material);
    this._mesh.scale.set(sizes.x, sizes.y, sizes.z);
    this._mesh.element = this;
  }

  loop() {
    const state = this._state;

    super.loop();

    const scaleX =
      this._mesh.scale.x +
      (this._sizes[state].x - this._mesh.scale.x) * this._scaleForce[state] +
      this._yOffset / 2;
    const scaleY =
      this._mesh.scale.y +
      (this._sizes[state].y - this._mesh.scale.y) * this._scaleForce[state] +
      this._yOffset / 2;
    this._mesh.scale.set(scaleX, scaleY, 1);

    this._material.uniforms.imageScale.value =
      this._material.uniforms.imageScale.value +
      (this._imageScale[state] - this._material.uniforms.imageScale.value) *
        0.4;

    this._material.uniforms.maskSize.value =
      this._material.uniforms.maskSize.value +
      (this._maskSize[state] - this._material.uniforms.maskSize.value) *
        this._maskForce[state];
    this._material.uniforms.aspectRatio.value =
      this._mesh.scale.x / this._mesh.scale.y;

    if (state === States.detail) {
      if (
        Maths.precission(this._material.uniforms.imageScale.value) ===
          Maths.precission(this._imageScale[state]) &&
        this._callback
      ) {
        this._callback();
        this._callback = null;
      }
    }
  }

  domPositionTo3D(__x, __y) {
    const x = -1 * Metrics.WIDTH * 0.5 + __x;
    const y = Metrics.HEIGHT * 0.5 - __y;

    return {
      x,
      y,
      z: 0
    };
  }

  toggleState(state, cb) {
    super.toggleState(state);

    this._material.uniforms.resolution.value = this.calculateResolution();
    this._callback = cb;
  }

  detailResize() {
    this.resize();
  }

  resize() {
    this.getSizes();
  }
}
