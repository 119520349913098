import * as THREE from 'three';
import gsap, { Linear, Power2 } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

import { Metrics } from '../_app/cuchillo/core/Metrics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics, isTouch, isDebug } from '../_app/cuchillo/core/Basics';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Maths } from '../_app/cuchillo/utils/Maths';
import Header from '../_app/cuchillo/layout/Header';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import ArticleImage from './Article__Image';
import ArticleText from './Article__Text';
import Starfield from './Starfield';
import { TimelineScroll } from '../scroll/TimelineScroll';
import States from './States';
import { WinCredits } from '../windows/Credits';

gsap.registerPlugin(DrawSVGPlugin);

export const DURATION = 2;

export default class Timeline {
  static perspective = 820;
  static fov = 60;
  static fov_y =
    Timeline.perspective * Math.tan(Maths.toRadians(Timeline.fov) / 2) * 2;
  static container;
  static scene;
  static renderer;
  static camera;
  static items = [];
  static particles;
  static state;
  static radius = 600;
  static radian_interval = Maths.toRadians(200);
  static separation = 2400;
  static gui;
  static raycaster;
  static mouse = new THREE.Vector2();
  static rotation = 0;
  static speed_factor = 0.04;
  static isEnabled = false;
  static navigation;
  static scroller;
  static scaleFactor = 2;

  static init(state = States.scroll) {
    this.radius = Metrics.WIDTH * 0.5;

    Timeline.state = state;
    Timeline.container = GetBy.id('timeline-canvas');

    Timeline.scene = new THREE.Scene();
    Timeline.renderer = new THREE.WebGLRenderer({
      canvas: Timeline.container,
      alpha: false
    });
    Timeline.renderer.setClearColor(0x1c1c1c, 1);
    Timeline.renderer.setSize(Metrics.WIDTH, Metrics.HEIGHT);
    Timeline.renderer.setPixelRatio(window.devicePixelRatio);

    Timeline.initLights();
    Timeline.initCamera();

    Timeline.particles = new Starfield(
      Timeline.renderer,
      Timeline.scene,
      Timeline.camera,
      500
    ); //ParticlesSystem(Timeline.scene, 150);
    Timeline.raycaster = new THREE.Raycaster();

    Timeline.scroller = GetBy.id('timeline-scroller');
    Timeline.ctas = GetBy.id('timeline-ctas');

    // Initailizes scroll
    TimelineScroll.init(TimelineScroll.AXIS_Y, {
      smooth: true,
      multiplicator: isTouch ? 2 : 0.5,
      container: GetBy.id('timeline-items')
    });

    // CENTER HELPERS
    if (isDebug) {
      const axesHelper = new THREE.AxesHelper(1000);
      Timeline.scene.add(axesHelper);
    }

    Timeline.selectNavigation();
  }

  static start() {
    Timeline.isEnabled = true;
    TimelineScroll.start();
    window.addEventListener(Basics.clickEvent, Timeline.raycast);
  }

  static stop() {
    Timeline.isEnabled = false;
    TimelineScroll.stop();
    window.removeEventListener(Basics.clickEvent, Timeline.raycast);
  }

  static animateFirstItem() {
    const first = GetBy.id('starter-item').nextElementSibling;

    TimelineScroll.gotoDOMElement(first);
  }

  static resetScroll() {
    const first = GetBy.id('starter-item');
    TimelineScroll.directGoto(first.offsetTop - 1);
  }

  static initLights() {
    const ambientlight = new THREE.AmbientLight(0xffffff, 2);
    Timeline.scene.add(ambientlight);
  }

  static initCamera() {
    const aspect = Metrics.WIDTH / Metrics.HEIGHT;
    const near = 0.1;
    const far = 10000;

    Timeline.camera = new THREE.PerspectiveCamera(
      Timeline.fov,
      aspect,
      near,
      far
    );
    Timeline.camera.position.set(0, 0, Timeline.perspective);
    Timeline.camera.lookAt(0, 0, 0);
  }

  static registerArticle(scrollItem) {
    const item = scrollItem.item;
    const id = scrollItem.id;
    const { type } = item.dataset;
    let Article;

    if (type === 'text') Article = ArticleText;
    else if (type === 'image') Article = ArticleImage;
    else return;

    const article = new Article(item, {
      id,
      radian_interval: Timeline.radian_interval,
      radius: Timeline.radius,
      scrollItem: scrollItem
    });

    Timeline.items[id] = article;
    Timeline.scene.add(article.instance);
  }

  static directGoToArticle(id, state = null) {
    const item = Timeline.items[id];
    TimelineScroll.directGoto(item._element.offsetTop - 1);

    if (state) item.directToggleState(state);
  }

  static goToArticle(id) {
    const item = Timeline.items[id];

    item.toggleState(Timeline.state, () => {
      TimelineScroll.gotoAnchor(id);
    });
  }

  static goToDetail(id) {
    const item = Timeline.items[id];

    TimelineScroll.stop();
    TimelineScroll.gotoAnchor(id);

    // setTimeout(() => {
    item.toggleState(States.detail, () => {
      ControllerPage.changePage(item.link, 'push');
    });
    // }, Math.max(200, Math.min(item._mesh.position.z * -0.3, 200)));

    Timeline.state = States.detail;
  }

  static toggleScroller(show) {
    gsap.to(Timeline.scroller, {
      opacity: show ? 1 : 0,
      ease: Power2.easeOut,
      duration: 0.8
    });
    gsap.to(Timeline.ctas, {
      opacity: show ? 1 : 0,
      ease: Power2.easeOut,
      duration: 0.8
    });
  }

  static openNavigation() {
    const bg = GetBy.selector('.bg', Timeline.navigation)[0];
    const svg = GetBy.selector('path', bg)[0];
    const numbers = GetBy.selector('.numbers', Timeline.navigation)[0];
    const circles = GetBy.selector('.circles', Timeline.navigation)[0];
    const bubbles = Array.from(GetBy.selector('.bubble', Timeline.navigation));

    gsap.killTweensOf(Timeline.navigation);

    Header.container.classList.add('active');

    TimelineScroll.stop();

    // Spiral
    gsap.to(bg, {
      duration: DURATION,
      opacity: 1,
      ease: Power2.easeOut
    });
    gsap.set(svg, { drawSVG: '0%' });
    gsap.to(svg, {
      duration: DURATION,
      drawSVG: '100%',
      ease: Power2.easeOut
    });

    // Numbers
    gsap.set(numbers, { opacity: 0 });
    gsap.to(numbers, {
      duration: DURATION,
      opacity: 1,
      ease: Power2.easeOut
    });

    // Bubbles
    gsap.set(bubbles, {
      opacity: 0,
      scale: 0.7,
      transformOrigin: 'center center'
    });
    gsap.to(circles, {
      duration: DURATION,
      opacity: 1,
      ease: Power2.easeOut
    });
    bubbles.map((bubble, i) => {
      gsap.to(bubble, {
        duration: 1,
        opacity: 1,
        scale: 1,
        delay: i * 0.1,
        ease: Power2.easeOut
      });
    });

    gsap.delayedCall(DURATION * 0.9, () => {
      Timeline.state = States.navigation;
    });

    Timeline.toggleScroller(false);

    for (var key in Timeline.items) {
      Timeline.items[key].toggleState(States.navigation);
    }
  }

  static closeNavigation() {
    const bg = GetBy.selector('.bg', Timeline.navigation)[0];
    const svg = GetBy.selector('path', bg)[0];
    const numbers = GetBy.selector('.numbers', Timeline.navigation)[0];
    const circles = GetBy.selector('.circles', Timeline.navigation)[0];
    const bubbles = Array.from(GetBy.selector('.bubble', Timeline.navigation));

    gsap.killTweensOf(Timeline.navigation);

    Timeline.state = States.scroll;
    Header.container.classList.remove('active');

    TimelineScroll.start();

    // Spiral
    gsap.to(bg, {
      duration: DURATION,
      opacity: 0,
      ease: Power2.easeOut
    });
    gsap.set(svg, { drawSVG: '100%' });
    gsap.to(svg, {
      duration: DURATION,
      drawSVG: '0%',
      ease: Power2.easeOut
    });

    // Numbers
    gsap.set(numbers, { opacity: 1 });
    gsap.to(numbers, {
      duration: DURATION / 2,
      opacity: 0,
      ease: Power2.easeOut
    });

    // Circles
    gsap.set(bubbles, {
      opacity: 1,
      scale: 1,
      transformOrigin: 'center center'
    });
    gsap.to(circles, {
      duration: DURATION,
      opacity: 0,
      ease: Power2.easeOut
    });
    gsap.to(bubbles, {
      duration: 0.8,
      opacity: 0,
      scale: 0.8,
      ease: Power2.easeOut
    });

    for (var key in Timeline.items) {
      Timeline.items[key].toggleState(States.scroll);
    }

    Timeline.toggleScroller(true);
  }

  static toggleNavigation() {
    if (Timeline.state === States.scroll) {
      Timeline.openNavigation();
    } else if (Timeline.state === States.navigation) {
      Timeline.closeNavigation();
    }
  }

  static selectNavigation() {
    if (Metrics.WIDTH >= Metrics.HEIGHT)
      Timeline.navigation = GetBy.id('menu-desktop');
    else Timeline.navigation = GetBy.id('menu-mobile');
  }

  static loop() {
    if (
      Timeline.renderer === undefined ||
      Timeline.scene === undefined ||
      Timeline.camera === undefined ||
      !Timeline.isEnabled
    )
      return;

    // Hover effect
    Timeline.toggleMouse();

    TimelineScroll.loop();

    for (var key in Timeline.items) {
      const item = Timeline.items[key];
      const progress = item.progress;

      // Blur
      const blur = Math.max(
        Math.min(Maths.normalize(0.1, 0.5, progress), 1),
        0
      );
      item.blur = blur / 512;

      // Opacity
      let opacity;
      if (progress < 0.5) {
        opacity = Math.max(Maths.normalize(0.2, -0.6, progress), 0);
      } else {
        opacity = Maths.normalize(0.52, 0.65, progress);
      }
      item.opacity = opacity;

      // Position and rotation
      const position = progress - 0.5;
      const radius = Timeline.radius;

      item.position = {
        x: -(Math.cos(Timeline.radian_interval * position) * radius) + radius,
        y: -(Math.sin(Timeline.radian_interval * position) * radius),
        z: Maths.lerp(0, Timeline.separation, position)
      };
      item.rotation = {
        x: 0,
        y: 0,
        z: Maths.lerp(0, Timeline.radian_interval, position)
      };

      item.loop();
    }

    Timeline.particles.loop();
  }

  static toggleMouse() {
    if (WinCredits.active) return;

    const mouse = {
      x: (Interaction.positions.mouse.x / Metrics.WIDTH) * 2 - 1,
      y: -(Interaction.positions.mouse.y / Metrics.HEIGHT) * 2 + 1
    };
    let cursor = 'default';

    if (Timeline.state === States.navigation) Timeline.resetNavigationTitles();

    Timeline.raycaster.setFromCamera(mouse, Timeline.camera);
    const intersects = Timeline.raycaster.intersectObjects(
      Timeline.scene.children
    );

    for (var i = 0; i < intersects.length; i++) {
      const target = intersects[i].object.element;

      if (target !== undefined) {
        if (
          target.type === 'image' &&
          ((target._material.uniforms.opacity.value > 0 &&
            Timeline.state === States.scroll) ||
            (target._material.uniforms.opacity.value <= 0.1 &&
              Timeline.state === States.navigation))
        ) {
          cursor = 'pointer';
          if (Timeline.state === States.navigation) {
            gsap.to(GetBy.id(`menu-${target.id}`), {
              opacity: 1,
              duration: 0.15
            });
            gsap.to(
              GetBy.selector(
                `[data-article="${target.id}"]`,
                Timeline.navigation
              )[0],
              {
                scale: 1.1,
                duration: 0.3,
                ease: Power2.easeOut
              }
            );
          }
        } else if (target._material.uniforms.opacity.value > 0) {
          cursor = 'default';
        }
      }
    }

    document.body.style.cursor = cursor;
  }

  static resetNavigationTitles() {
    gsap.to(GetBy.selector('#menu-titles li'), {
      duration: 0.15,
      opacity: 0
    });
    gsap.to(GetBy.selector(`.circles .bubble`, Timeline.navigation), {
      scale: 1,
      duration: 0.3,
      ease: Power2.easeOut
    });
  }

  static raycast(event) {
    if (Timeline.state === States.detail || WinCredits.active) return;

    const mouse = {
      x: (event.clientX / Metrics.WIDTH) * 2 - 1,
      y: -(event.clientY / Metrics.HEIGHT) * 2 + 1
    };

    Timeline.raycaster.setFromCamera(mouse, Timeline.camera);
    const intersects = Timeline.raycaster.intersectObjects(
      Timeline.scene.children
    );

    for (var i = 0; i < intersects.length; i++) {
      const target = intersects[i].object.element;

      // Comprobar opacity para no tener "falsos"
      if (
        target !== undefined &&
        target.type === 'image' &&
        ((target._material.uniforms.opacity.value > 0 &&
          Timeline.state === States.scroll) ||
          (target._material.uniforms.opacity.value <= 0.1 &&
            Timeline.state === States.navigation))
      ) {
        Timeline.closeNavigation();
        Timeline.resetNavigationTitles();
        Timeline.goToDetail(target.id);
      }
    }
  }

  static resetArticleStates() {
    for (var key in Timeline.items) {
      Timeline.items[key].toggleState(States.scroll);
    }
  }

  static resize() {
    this.radius = Metrics.WIDTH * 0.35;

    Timeline.selectNavigation();

    if (Timeline.renderer) {
      Timeline.renderer.setSize(Metrics.WIDTH, Metrics.HEIGHT);
      Timeline.camera.aspect = Metrics.WIDTH / Metrics.HEIGHT;
      Timeline.camera.updateProjectionMatrix();

      Object.keys(Timeline.items).map((key) => {
        Timeline.items[key].resize();
      });
    }

    if (Timeline.particles) {
      Timeline.particles.dispose();

      Timeline.particles = new Starfield(
        Timeline.renderer,
        Timeline.scene,
        Timeline.camera,
        500
      );
    }
  }
}
