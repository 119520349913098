import gsap, { Power2 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { TimelineScroll } from '../scroll/TimelineScroll';
import Header from '../layout/Header';
import Timeline from '../3D/Timeline';
import States from '../3D/States';
import Default from './Default';

export default class Article extends Default {
  _articleID;
  _backBtn;
  _loader;
  _scrollbar;

  constructor() {
    super();

    this._articleID = this.container.dataset.id;

    if (isMobile) {
      GetBy.class('__desktop')[0].remove();
    } else {
      GetBy.class('__mobile')[0].remove();
    }

    this._backBtn = GetBy.id('timeline-back');
    this._call = () => {
      this.back();
    };

    this._loader = GetBy.id('page-loader');
    this._scrollbar = GetBy.id('Scrollbar');
  }

  beforeShow() {
    super.beforeShow();

    if (this._backBtn)
      this._backBtn.addEventListener(Basics.clickEvent, this._call);

    if (this.isFirstTime) {
      Timeline.init(States.detail);
      Timeline.start();
      Timeline.directGoToArticle(this._articleID, States.detail);
      TimelineScroll.resize();
      TimelineScroll.stop();
    }

    Scroll.init(Scroll.AXIS_Y, {
      smooth: !isMobile,
      multiplicator: 0.8,
      container: !isMobile ? document.body : GetBy.selector('#Main .wrap')[0],
      element: !isMobile ? window : GetBy.selector('#Main .wrap')[0]
    });
    Scroll.setScrollbar(new Scrollbar());
  }

  afterShow() {
    super.afterShow();

    Scroll.start();

    this._scrollbar.style.display = 'block';
    gsap.set(this._scrollbar, {
      opacity: 0
    });
    gsap.to(this._scrollbar, {
      opacity: 1,
      duration: 0.3,
      ease: Power2.easeOut
    });

    Timeline.resetArticleStates();
  }

  beforeHide() {
    super.beforeHide();

    gsap.to(this._scrollbar, {
      opacity: 0,
      duration: 0.3,
      ease: Power2.easeOut,
      onComplete: () => {
        this._scrollbar.style.display = 'none';
      }
    });
  }

  afterHide() {
    if (this._backBtn)
      this._backBtn.removeEventListener(Basics.clickEvent, this._call);

    Header.show();
    Scroll.dispose();

    super.afterHide();
  }

  back() {
    TimelineScroll.start();

    gsap.delayedCall(0.3, () => {
      Timeline.goToArticle(this._articleID);
      ControllerPage.changePage('/experience', 'push');
    });
  }
}

ControllerPage._addPage('article', Article);
