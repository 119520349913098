export const IMAGE_VERTEXT = `
    #define PI 3.1415926538

    varying vec2 vUv;
    varying float wave;

    uniform float velocity;
    uniform float time;

    void main() {
        vUv = uv;

        vec3 pos = position;

        // We add a constant wave that will depend on a variable that will be
        // animated from 0.0 to 1.0 when transitioning from view to view
        pos.z += velocity * sin(vUv.x * PI * 0.5 * time);

        // We assign this displacement to a varying send to the fragment in order
        // to be applied to the texture's pixel position
        wave = pos.z;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const TEXT_VERTEX = `
    #define PI 3.1415926538

    varying vec2 vUv;

    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;