import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Timeline from '../3D/Timeline';
import Default from './Default';
import { TimelineScroll } from '../scroll/TimelineScroll';

export default class Home extends Default {
  _coverLayer;
  _coverTexts;
  _coverTitle;
  _coverCompany;
  _coverImage;
  _startCTA;

  constructor() {
    super();

    this.setUpIntro();
  }

  setUpIntro() {
    this._coverLayer = GetBy.id('cover');
    this._coverTitle = GetBy.id('title');
    this._coverCompany = GetBy.id('company');
    this._coverImage = GetBy.id('image');
    this._canvas = document.getElementById('timeline-canvas');
    this._coverTexts = GetBy.selector('.__text', this._coverLayer);
    this._startCTA = GetBy.class('__start-experience', this._coverLayer)[0];

    this._startCTA.addEventListener(Basics.clickEvent, () => {
      this.startExperience();
    });

    const items = [
      this._coverLayer,
      this._coverCompany,
      this._coverImage,
      this._coverTitle
    ];

    gsap.set(items, { opacity: 0 });

    if (this.isFirstTime) gsap.set(this._canvas, { opacity: 0 });

    if (isMobile) {
      GetBy.selector('.__media-desktop', this._coverImage)[0].remove();
    } else {
      GetBy.selector('.__media-mobile', this._coverImage)[0].remove();
    }
  }

  //SHOW
  beforeShow() {
    super.beforeShow();

    if (this.isFirstTime) {
      Timeline.init();
    } else {
      gsap.to(this._canvas, {
        opacity: 0,
        duration: 0.3,
        ease: 'Linear.easeNone'
      });
    }

    Scroll.init(Scroll.AXIS_Y, {
      smooth: !isMobile,
      multiplicator: 0.8,
      container: !isMobile ? document.body : GetBy.selector('#Main .wrap')[0],
      element: !isMobile ? window : GetBy.selector('#Main .wrap')[0]
    });
  }

  afterShow() {
    super.afterShow();

    Timeline.resetScroll();
    TimelineScroll.stop();

    Scroll.start();

    if (GetBy.selector('video', this._coverImage)[0]) {
      GetBy.selector('video', this._coverImage)[0].play();
    }

    gsap.to(this._coverLayer, {
      opacity: 1,
      duration: 0.3,
      ease: 'Linear.easeNone'
    });
    gsap.to(this._coverCompany, {
      opacity: 1,
      duration: 1.5,
      delay: 0.5,
      ease: 'Linear.easeNone'
    });
    gsap.to(this._coverTitle, {
      opacity: 1,
      duration: 3,
      delay: 0.8,
      ease: 'Linear.easeNone'
    });
    gsap.to(this._coverImage, {
      opacity: 1,
      duration: 3,
      delay: 1.5,
      ease: 'Linear.easeNone'
    });
    [...this._coverTexts, this._startCTA].map((item, index) => {
      gsap.to(item, {
        opacity: 1,
        duration: 1 + Math.pow(1.12, index + 1),
        delay: 3,
        ease: 'Linear.easeNone'
      });
    });
  }

  beforeHide() {
    Timeline.start();
  }

  afterHide() {
    super.afterHide();
    Scroll.dispose();
    gsap.to(this._canvas, {
      opacity: 1,
      duration: 0.6,
      ease: 'Linear.easeNone'
    });
    Timeline.animateFirstItem();
  }

  startExperience() {
    gsap.to(this._coverLayer, {
      duration: 0.5,
      opacity: 0,
      ease: 'Linear.easeNone',
      onComplete: () => {
        this._coverLayer.style.display = 'none';

        ControllerPage.changePage('/experience', 'push');
      }
    });
  }
}

ControllerPage._addPage('home', Home);
