import BG from '../_app/cuchillo/layout/Background';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__BG extends VScroll_Item {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onShow = () => {
      document.body.classList.add('scrolled')
    }

    this.onHide = () => {
      document.body.classList.remove('scrolled')
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  dispose () {
    super.dispose();
  }

  resize (__w, __h) {
    super.resize(__w, __h);
  }
}

Scroll._registerClass('bg', ScrollItem__BG);
