import gsap, { Linear } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';
import VTimelineScroll_Item from './VTimelineScroll_Item';
import { TimelineScroll } from './TimelineScroll';
import Timeline from '../3D/Timeline';

class ScrollItem__Article extends VTimelineScroll_Item {
  cta;
  scroller;
  bullets;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    Timeline.registerArticle(this);

    this.scroller = GetBy.id('timeline-scroller');
    this.bullets = GetBy.selector('li', this.scroller);

    if (this.item.dataset.type === 'image') {
      this.cta = GetBy.id(this.item.dataset.cta);
      this.cta.addEventListener(Basics.clickEvent, () => {
        const id = this.cta.getAttribute('href').replace('#', '');
        Timeline.goToDetail(id);
      });
    }

    this.onShow = () => {
      for (let i = 0; i < this.bullets.length; i++) {
        const bullet = this.bullets[i];

        if (bullet.getAttribute('id') === this.item.dataset.chapter) {
          bullet.classList.add('active');
        } else {
          bullet.classList.remove('active');
        }
      }
    };

    this.onHide = () => {};

    this.onMove = () => {
      if (
        this.item.dataset.type === 'image' &&
        this.progress > 0.2 &&
        this.progress < 0.55
      ) {
        this.cta.classList.add('visible');
        gsap.to(this.cta, {
          opacity: 1,
          duration: 0.3,
          ease: Linear.easeOut
        });
      } else if (this.item.dataset.type === 'image') {
        gsap.to(this.cta, {
          opacity: 0,
          duration: 0.3,
          ease: Linear.easeOut,
          onComplete: () => {
            this.cta.classList.remove('visible');
          }
        });
      }
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  dispose() {
    super.dispose();
  }

  resize(__w, __h) {
    super.resize(__w, __h);
  }
}

TimelineScroll._registerClass('scroll-article', ScrollItem__Article);
