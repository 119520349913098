export const Colors = {
  "white": {
    hex: 0xFFFFFF,
    css: "#F5F5F5",
    str: "palette-white"
  },
  "black": {
    hex: 0x1C1C1C,
    css: "#1C1C1C",
    str: "palette-black"
  },
  "primary": {
    hex: 0x1C1C1C,
    css: "#1C1C1C",
    str: "palette-primary"
  },
  "secondary": {
    hex: 0xF5F5F5,
    css: "#F5F5F5",
    str: "palette-secondary"
  }
}