import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { TimelineScroll } from '../scroll/TimelineScroll';
import Header from '../layout/Header';
import Timeline from '../3D/Timeline';
import States from '../3D/States';
import Default from './Default';

export default class Article extends Default {
  beforeShow() {
    super.beforeShow();

    if (this.isFirstTime) {
      Timeline.init();
      TimelineScroll.resize();
    } else {
      for (var key in Timeline.items) {
        Timeline.items[key].directToggleState(States.scroll);
      }
      Timeline.state = States.scroll;
    }

    Timeline.start();
    Header.show();
  }

  afterShow() {
    super.afterShow();
    if (this.isFirstTime) Timeline.animateFirstItem();
  }

  beforeHide() {
    Header.hide();
  }

  resize() {
    super.resize();
    // TimelineScroll.resize();
  }
}

ControllerPage._addPage('experience', Article);
